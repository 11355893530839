import React from "react"
import Layout from "../components/layout"
import SEO from '../components/seo';


const CATEGORY_MAP = {
    'terms-and-conditions': 'Terms and Conditions',
    'privacy-policies': 'Privacy Policies',
};

const DESC = {
    'terms-and-conditions': 'J Clarke Fitness Conditions of Terms of Use. Conditions of Contract and legal notices.',
    'privacy-policies': 'Privacy Policy explains how J Clarke Fitness collects and uses your information necessary to provide you with customised fitness plans.',
}

export default function Policy({ pageContext }) {
    const { policyDoc, category } = pageContext;


    return (
        <Layout>
            <SEO
                title={`${CATEGORY_MAP[category]} | J Clarke Fitness`}
                description={DESC[category]}
            />
            <div className="content">
                <div dangerouslySetInnerHTML={{__html: policyDoc.content }} />
            </div>
            <style jsx>{`
            .content {
                background-color: hsl(4,16%,96%);

                & > div {
                    max-width: 692px;
                    width: 100%;
                    padding: 32px 24px 40px;
                    box-sizing: border-box;
                    margin: 0 auto;

                    & > ol > li {
                        margin: 32px 0 0;
                        font-weight: 700;
                        color: rgb(38, 50, 56);

                        & > ol {
                            margin: 8px 0 0;
                            font-weight: 400;
                            color: rgba(38, 50, 56, 0.7);

                            & > li {
                                margin: 8px 0 0;

                                & > ol {
                                    list-style-type: lower-alpha;
                                    padding-left: 16px;

                                    &:first-child {
                                        margin-top: 0;
                                    }

                                    & > li {
                                        display: list-item;
                                        padding-left: 0.6em;

                                        &:before {
                                            content: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            @media (min-width: 800px) {
                .content {
                    & > div {
                        padding: 30px 30px 40px;
                    }
                }
            }

            ol {
                list-style-type: none;
                counter-reset: item;
                margin: 0;
                padding: 0;
            }

            ol > li {
                display: table;
                counter-increment: item;
            }

            ol > li:before {
                content: counters(item, '.') '. ';
                display: table-cell;
                padding-right: 0.6em;
            }
        `}</style>
        </Layout>
    )
}